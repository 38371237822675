import React, { useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { transparentize } from "polished";
import { navigate } from "gatsby";
import { useSelector, useDispatch } from "react-redux";
import { acceptAgeGate, clearAgeGateError } from "../ducks/ageGateSlice";
import { useDarkMode } from "../ducks/colourModeSlice";
import { useUpdateHeaderActions } from "../ducks/headerActionsSlice";
import useWindowWidth from "../hooks/useWindowWidth";
import useDigitInput from "../hooks/useDigitInput";
import SEO from "../components/common/seo";
import CTAButton from "../components/common/cta-button";
import { ExternalLink } from "../components/common/links";
import { H2, Body, Detail } from "../components/common/typography/";
import { Error } from "../utils/formHelpers";
import ageGateBottle from "../assets/images/AgeGateBottle.png";

const AgeGate = () => {
  useDarkMode();
  useUpdateHeaderActions({
    left: null,
    right: null,
  });

  const hasPassed = useSelector((state) => state.ageGate.hasPassed);
  const redirectTo = useSelector((state) => state.ageGate.intendedDestination);
  useEffect(() => {
    if (hasPassed) navigate(redirectTo);
  }, [hasPassed, redirectTo]);

  const theme = useContext(ThemeContext);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth && windowWidth <= theme.maxMobileSize;
  const dispatch = useDispatch();

  const ageError = useSelector((state) => state.ageGate.error);
  useEffect(() => {
    if (ageError) setTimeout(() => dispatch(clearAgeGateError()), 5000);
  }, [ageError, dispatch]);

  const [year, setYear] = React.useState("");
  const setYearAndClearError = (args) => {
    dispatch(clearAgeGateError());
    setYear(args);
  };
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value: year,
    onChange: setYearAndClearError,
  });

  const setPassed = () => dispatch(acceptAgeGate({ year }));

  return (
    <Root>
      <SEO title="Welcome" />
      <Title>Welcome to the Fine &amp; Rare Collection</Title>
      <Intro>
        You must be of legal drinking age to enter. What year were you born?
      </Intro>
      <Digits>
        <Digit type="tel" inputMode="decimal" {...digits[0]} />
        <Digit type="tel" inputMode="decimal" {...digits[1]} />
        <Digit type="tel" inputMode="decimal" {...digits[2]} />
        <Digit type="tel" inputMode="decimal" {...digits[3]} />
      </Digits>
      <ErrorWrapper>
        <Error content={ageError}>
          That's not a valid year of birth for entry
        </Error>
      </ErrorWrapper>
      <CTAButton text="Enter" action={setPassed} fullWidth={isMobile} />
      <Cookies>
        <Detail>
          We use cookies to make your experience better. By using this site you
          consent to our{" "}
          <ExternalLink
            href="https://www.glengoyne.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </ExternalLink>
          .
        </Detail>
      </Cookies>
    </Root>
  );
};

const Root = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 146px ${({ theme }) => theme.spacing.xl};
  background-image: url(${ageGateBottle});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background: none;
  }
`;

const Title = styled(H2)`
  text-align: center;
  max-width: 560px;
`;

const Intro = styled(Body)`
  text-align: center;
  max-width: 320px;
`;

const Digits = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.lg} auto 0 auto;
`;

const Digit = styled.input`
  display: block;
  font-family: "GGNumbers";
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 77px;
  text-align: center;
  width: 25%;
  max-width: calc(1em + 16px);
  padding: 0 ${({ theme }) => theme.spacing.xxs};
  padding-bottom: 10px;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.5, theme.colour.fullWhite)};
  color: ${({ theme }) => transparentize(0.5, theme.colour.fullWhite)};
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colour.fullWhite};
    color: ${({ theme }) => theme.colour.fullWhite};
  }
`;

const ErrorWrapper = styled.div`
  height: 30px;
  margin: ${({ theme }) => theme.spacing.sm} auto;
`;

const Cookies = styled.div`
  max-width: 650px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
  position: fixed;
  bottom: 0;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  text-align: center;
  z-index: 999;
`;

export default AgeGate;
